import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

import ProfileLinkComponent from "../../templates/common/profile-link.component"
// import ResumeSectionHeaderComponent from "../../templates/resume-section-header.component"
import ArticleSummaryAuthorComponent from "../../templates/content/blog-author-summary.component"
import WorkItemComponent from "../../templates/work-item.component"
import WorkItemKey from "../../WorkItemKey"

import ProjectItemComponent from "../../templates/project-item.component"
import ProjectKey from "../../ProjectKey"
import HeaderComponent from "../../templates/header-post.component"
import Footer from "../../templates/footer.component"
import BloggerFooterLink from "../../templates/blogger-link.component"
import PageSidebar from "../../templates/page-sidebar.component"
import LinksContainer from "../../templates/links-container.component"
import Navigation from "../../templates/navigation.component"

const _ = require("lodash")
export default function Template({
  data, // this prop will be injected by the GraphQL query below.
  pageContext
}) {
  const { resumeJson } = data;
  const { basics, projects, work } = resumeJson;
  const articles = data.posts.nodes;
  const bloggers = data.bloggers.edges.reduce((model, blogger) => {
    model[blogger.node.basics.id] = {
      "name": blogger.node.basics.name,
      "email": blogger.node.basics.email
    }
    return model;
  }, {});
  let navLinks  = []
  navLinks.push({name: "Back to Top", href: "#top", className:"text-sm text-right" });
  navLinks.push({name: "Work Experience", className:"text-xl" });
  for (const item of work) {
     navLinks.push({name : `${item.company} - ${item.startDate}`, href:"#" + WorkItemKey(item), className:"text-sm" });
  } 

  navLinks.push({name: "Projects", className:"text-xl" });
  for (const item of projects) {
     navLinks.push({name : `${item.name}`, href:"#" + ProjectKey(item), className:"text-sm" });
  } 

  navLinks.push({name: "Recent Posts", className:"text-xl" });
  for (const item of articles) {
     navLinks.push({name : `${item.frontmatter.title}`, href:`#${_.kebabCase(item.frontmatter.slug)}`, className:"text-sm" });
  } 

  return (
    <div id="top" className="">
      <Helmet>
        <title>bitCobblers - {basics.name}</title>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css"></link>   
        <html lang="en" />                                     
      </Helmet>
      <HeaderComponent></HeaderComponent>              
      <Navigation />    
      <div className="max-w-screen-xl mx-auto p-5" >                         
          <PageSidebar sidebar={<LinksContainer links={navLinks} />}>
          <div className="space-y-5 pt-5">
              <div className="flex">                                      
                <div>
                  <h1>{basics.name}</h1>
                  <h4 className="uppercase text-sm text-gray-500/60">{basics.label}</h4>              
                </div>
                <div className="flex-grow">              
                  <BloggerFooterLink blogger={basics.id} list={bloggers}/>                        
                </div>
                <div className="text-right">          
                  <ProfileLinkComponent type="Email" link={"mailto:" + basics.email} label={basics.email}></ProfileLinkComponent>
                  {basics.profiles.map(profile => (
                    <ProfileLinkComponent key={profile.network} type={profile.network} link={profile.url} label={profile.username}></ProfileLinkComponent>
                  ))}
                </div>                
              </div>  
              <div className="bd-intro">
                    <p className="bd-lead">{basics.summary}</p>
                  </div>                
              <div id="work-experience">
                <h1>Work Experience</h1>
              </div>           
              {work.map((item, index) => (
                <WorkItemComponent key={index} article={item}></WorkItemComponent>
              ))}

              <div id="projects">
                <h1>Projects</h1>
              </div>
              {projects.map((item, index) => (
                <ProjectItemComponent key={index} project={item}></ProjectItemComponent>
              ))}

              <div id="recent-posts">
                <h1>Recent Posts</h1>
              </div>
              {articles.map((article, index) => (
                <ArticleSummaryAuthorComponent key={article.frontmatter.slug} article={article}></ArticleSummaryAuthorComponent>
              ))}
              </div>              
          </PageSidebar>           
          {/* <Footer></Footer>  */}
      </div>                  
              
  </div>
  )
}
export const pageQuery = graphql`
query ($id: String, $basics__id: String) {
  posts: allMdx(
    sort: {order: DESC, fields: frontmatter___date}
    filter: {frontmatter: {blogger: {eq: $basics__id}, published: {eq: true}, slug: {regex: "/\/b\/.*/"}}}
  ) {
    nodes {
      frontmatter {
        slug
        title
        blogger
        date
        tags
      }
      excerpt(pruneLength: 780)
    }
  }
  books: allMdx(
    sort: {order: DESC, fields: frontmatter___date}
    filter: {frontmatter: {blogger: {eq: $basics__id}, published: {eq: true}, slug: {regex: "/\/book\/.*/"}}}
  ) {
    nodes {
      frontmatter {
        slug
        title
        blogger
        date
        tags
      }
      excerpt(pruneLength: 480)
    }
  }
  resumeJson(id: {eq: $id}) {
    basics {
      id
      email
      name
      label
      summary
      profiles {
        network
        url
        username
      }
      picture
      location {
        city
        countryCode
        postalCode
      }
    }
    work {
      endDate(formatString: "MMM, YYYY")
      highlights
      startDate(formatString: "MMM, YYYY")
      position
      summary
      company
      website
    }
    skills {
      keywords
      level
      name
    }
    languages {
      fluency
      language
    }
    interests {
      keywords
      name
    }
    projects {
      description
      highlights
      name
      url
    }
  }
  bloggers: allResumeJson {
    edges {
      node {
        basics {
          id
          email
          name
        }
      }
    }
  }
}
`