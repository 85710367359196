import * as React from "react"
import { Link } from "gatsby"

const _ = require("lodash")

const BlogAuthorSummary = ({article}) => {
    const {slug,date, title} = article.frontmatter;
    return (
    <div id={_.kebabCase(slug)} className="pt-3">                            
        <div className="row">            
            <div className="col">
                <Link to={slug} style={{ textDecoration:"none", fontWeight:600}}>{title}</Link>         
            </div>
            <div className="col" style={{textAlign:"right"}}>
                <time className="post-date" dateTime={date}>{date}</time> 
            </div>
        </div>
        <section className="post-excerpt">
            <p>{article.excerpt} <Link to={slug} className="read-more">»</Link></p>
        </section>           
    </div>
)}

export default BlogAuthorSummary;