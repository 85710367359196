import * as React from "react"
import ProjectKey from "../ProjectKey"

const ProjectItemComponent = ({project}) => {        
    const id = ProjectKey(project);
    return (
    <div  id={id}>
        
        <label className="hidden">
            <input type="checkbox" defaultChecked="checked"/>        
        </label>

        <header className="flex">                
            <div className="flex align-text-bottom">
                <h3 className="">{project.name}</h3>                
            </div>             
            <div className="flex-grow"></div>               
            <div className="text-right">                
                <span className="">
                    <a href={project.url} rel="noreferrer" target="_blank">{project.url}</a>
                </span>      	
            </div>                                
        </header>                
        <div className="py-5">{project.description}</div>
        <ul className="">
            {project.highlights.map((point, index) => (<li key={index} className="list-disc ml-10"><p>{point}</p></li>))}
        </ul>        
    </div>)}

export default ProjectItemComponent