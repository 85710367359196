import * as React from "react"

const PageSidebar = ({ sidebar, children }) => (
  <div className="              
      max-w-screen-xl   
      flex 
      flex-col
      md:flex-row
      space-x-5                    
      mx-auto
      relative
      pb-10
    ">
    <div className="px-5">
    {children}
    </div>
    <div className="
      md:h-screen        
      text-center            
      md:w-52
      md:text-left
      md:flex-none
      md:sticky 
      md:top-0    
      z-20                      
    ">
      {sidebar}
    </div>
  </div>
)

export default PageSidebar