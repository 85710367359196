import * as React from "react"
import WorkItemKey from "../WorkItemKey"

const WorkItemComponent = ({article}) => {            
    const id = WorkItemKey(article);    
    return (
    <div id={id}>        
        <label className="hidden">
            <input type="checkbox" defaultChecked="checked"/>        
        </label>
        <header className="flex justify-between">                
            <h3>{article.company}</h3>                                                                            
            <span className="website">
                <a href={article.website} rel="noreferrer" target="_blank">{article.website}</a>
            </span>      	
        </header>
        <div className="flex justify-between">
            <h5 className="">{article.position}</h5>            
            <h5 className="date text-xs uppercase">
                {article.startDate} - {article.endDate ? article.endDate : "Current"}
            </h5>                                    
        </div>        
        <div className="">
        <p></p><p>{article.summary}</p><p></p>
        </div>
        <ul className="highlights">
            {article.highlights.map((point, index) => (<li key={index} className="list-disc ml-10">{point}</li>))}
        </ul>        
    </div>)}

export default WorkItemComponent;
