import * as React from "react"
import profiles from "../../../content/profiles.json"

const ProfileLinkComponent = ({type, label, link}) => {
    var profile = profiles[type];
    return (
        <div className={profile.className}>
            <i className={"bi " + profile.icon}></i> <a href={link}>{label}</a>
        </div>
    )
}

export default ProfileLinkComponent