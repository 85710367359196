import * as React from "react"
import gravatar from "../gravatar"
import { Link } from "gatsby"

const BloggerFooterLink = ({ blogger, list}) => (
  <footer className="flex justify-center">              
      <Link to={"/cobbler/" + blogger + "/"}>
          <img className="rounded-full"
                src={"//www.gravatar.com/avatar/" + gravatar(list, blogger) + "?d=404&amp;s=250"} />
      </Link>              
  </footer>  
)

export default BloggerFooterLink